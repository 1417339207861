





























































import Vue, { PropType } from 'vue';
import logo from '../../assets/potok-logo.svg';

type Route = {
  text: string
  href: string;
  to?: string;
  event?: unknown;
  testLabel?: string;
  testValue?: string;
}

export default Vue.extend({
  name: 'AppNavbar',
  props: {
    currentApp: {
      type: String,
      default: '/',
    },
    items: {
      type: Array as PropType<Route[]>,
      default: () => [] as Route[],
    },
    brandUrl: {
      type: String,
      default: null,
    },
    clippedRight: {
      type: Boolean,
      default: false,
    },
    labelText: {
      type: String,
      default: '',
    },
    brandName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      logo,
    };
  },
  computed: {
    isLabelVisible() {
      return Boolean(this.labelText || this.$scopedSlots.label);
    },
  },
  methods: {
    isExternalLink(route: Route) {
      return (!route.event && route.href) || undefined;
    },
  },
});
